/** Imports **/
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "custom";
@import url("https://fonts.googleapis.com/css2?family=Hina+Mincho&family=Roboto+Mono&display=swap");

/** Global variables **/
$backgroundColor: #000;
$primaryColor: #070a33;
$textFont: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN",
	"Noto Sans JP", Meiryo, sans-serif;
$serifFont: "Hina Mincho", serif;
$monocodeFont: "Roboto Mono", monospace;
$textColor: #ededed;
$mutedTextColor: #7c7c7c;
$accentColor: #aa8f6a;
$terciaryColor: #909093;
$borderColor: #1f2247;

/** Global structure **/
/* Base structure */
*,
*:before,
*:after {
	box-sizing: border-box;
}
body,
h1,
h2,
h3 {
	margin: 0;
}
a {
	text-decoration: none;
}
body {
	max-width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	font-size: calc(0.0625rem * 16);
	font-family: $textFont;
	background: linear-gradient(
		-45deg,
		$primaryColor,
		$primaryColor,
		$backgroundColor,
		$backgroundColor
	);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-image: url(https://images.unsplash.com/photo-1583263496887-4e3046c78090?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80);
		// Photo by <a href="https://unsplash.com/@jhonjim?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jhon Jim</a> on <a href="https://unsplash.com/@tomasjjjjj/likes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
		opacity: 0.9;
		z-index: -1;
		clip-path: polygon(0% -60%, 70% 100%, 0% 100%, 0% 100%);
		background-repeat: no-repeat;
		background-size: auto;
	}
}
/* Background credit: Manuel Pinto: https://codepen.io/P1N2O/pen/pyBNzX */
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/** Navbars **/
/* Navbar Top */
header {
	height: 10vh;
	.NavbarTop {
		padding: calc(0.0625rem * 16);
		position: sticky;
		.logoText {
			margin: 0;
			font-family: $serifFont;
			.senrei {
				color: $accentColor;
			}
			.dot {
				color: $mutedTextColor;
			}
			.jp {
				color: $mutedTextColor;
			}
		}
		.beta {
			font-family: $monocodeFont;
			font-size: calc(0.0625rem * 18);
			color: $mutedTextColor;
		}
	}
}
footer {
	height: 10vh;
	.navbarBottom {
		padding: calc(0.0625rem * 16) calc(0.0625rem * 16) calc(0.0625rem * 20)
			calc(0.0625rem * 16);
		font-size: 0.75rem;
		.navbarBottomList {
			display: flex;
			flex-direction: row;
			.navbarBottomLinks {
				color: $mutedTextColor !important;
				margin-right: 0.75rem;
				padding-left: 0 !important;
				&:hover {
					color: $terciaryColor !important;
				}
			}
		}
	}
}

/** Pages **/
/* Home Content */
.homeContent {
	height: 80vh;
	padding: calc(0.0625rem * 16);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	.heroText {
		flex: 1 1 50%;
		min-width: 30ch;
		header {
			height: auto;
			margin-bottom: calc(0.0625rem * 16);
			.title {
				margin-bottom: calc(0.0625rem * 16);
				font-family: $textFont;
				font-size: clamp(3.3rem, 5vw, 3rem);
				line-height: 1;
				color: $accentColor;
				.alphTitle {
					display: inline-block;
					font-family: $serifFont;
					font-size: clamp(3.5rem, 5vw, 3rem);
				}
			}
		}
		.subtitle {
			margin-bottom: calc(0.0625rem * 16);
			font-family: $textFont;
			font-size: clamp(1.75rem, 5vw, 2rem);
			color: $terciaryColor;
		}
		.description {
			margin-bottom: calc(0.0625rem * 10);
			font-size: clamp(1rem, 5vw, calc(0.0625rem * 16));
			color: $mutedTextColor;
		}
		.leaveMailDescription {
			margin-bottom: calc(0.0625rem * 16);
			font-size: clamp(1rem, 5vw, calc(0.0625rem * 16));
			color: $mutedTextColor;
		}
		.leaveMailGroup {
			width: 80%;
			.leaveMailInput {
				margin-right: 0.8rem;
				padding: 0.375rem 0.2rem;
				background-color: transparent;
				font-family: $monocodeFont;
				color: $accentColor;
				border: none;
				border-bottom: 1px solid $borderColor;
				&:focus {
					border: none;
					box-shadow: 0 0 0rem $accentColor;
					border-bottom: 1px solid $accentColor;
					color: $accentColor;
				}
			}
			.shareMailButton {
				margin: 0;
				background-color: transparent;
				border: 0;
				&:focus {
					border: none;
					box-shadow: 0 0 0rem $accentColor;
				}
				.shareMailButtonIcon {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 100%;
					&:hover {
						color: $accentColor;
					}
				}
			}
		}
		.dontShare {
			margin-top: calc(0.0625rem * 2);
			margin-bottom: calc(0.0625rem * 10);
			font-size: calc(0.0625rem * 12);
			color: $mutedTextColor;
			.dontShareLink {
				color: $accentColor;
			}
		}
	}
	.heroDevices {
		flex: 1 1 50%;
		position: relative;
		.macbookPro {
			z-index: 1;
			position: relative;
			top: 0;
			left: 0;
			max-width: 100%;
		}
		.iPhone {
			z-index: 2;
			position: absolute;
			top: -5rem;
			right: 0rem;
			max-width: 40%;
		}
	}
}
/* Single Page Template */
.singlePage {
	/* Hero Text */
	.heroText {
		.subtitle,
		.description {
		}
	}
	/* Single Go Back */
	.singlePageGoBack {
		margin-top: calc(0.0625rem * 50);
		padding-bottom: calc(0.0625rem * 10);
		.singlePageGoBackSpan {
			:hover {
				padding-bottom: calc(0.0625rem * 2);
				border-bottom: calc(0.0625rem * 1) dotted $accentColor;
			}
			.singlePageGoBackLink {
				color: $accentColor;
			}
		}
	}
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) and (max-width: 480px) {
	body {
		&:after {
			background-size: cover;
			clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 60%);
		}
	}
	header {
		.NavbarTop {
			padding: 1.25rem;
			.logoText {
				.senrei {
				}
				.dot {
				}
				.jp {
				}
			}
			.beta {
			}
		}
	}
	footer {
		.navbarBottom {
			padding: 1.25rem;
			.navbarBottomList {
				.navbarBottomLinks {
					&:hover {
					}
				}
			}
		}
	}
	.homeContent {
		padding: 1.25rem;
		.heroText {
			header {
				.title {
					.alphTitle {
					}
				}
			}
			.subtitle {
			}
			.description {
			}
			.leaveMailDescription {
			}
			.leaveMailGroup {
				width: 100%;
				.leaveMailInput {
					&:focus {
					}
				}
				.shareMailButton {
					&:focus {
					}
					.shareMailButtonIcon {
						color: $accentColor;
						&:hover {
						}
					}
				}
			}
		}
		.heroDevices {
			margin-top: 2rem;
			.macbookPro {
				max-width: 90%;
			}
			.iPhone {
				top: -2.5rem;
				right: calc(0.0625rem * 24);
			}
		}
	}
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) and (max-width: 640px) {
}
/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) and (max-width: 960px) {
	body {
		&:after {
			background-size: cover;
			clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 47%);
		}
	}
	.homeContent {
		.heroText {
			flex: 1 1 60%;
			header {
				.title {
					.alphTitle {
					}
				}
			}
			.subtitle {
			}
			.description {
			}
			.leaveMailDescription {
			}
			.leaveMailGroup {
				.leaveMailInput {
					&:focus {
					}
				}
				.shareMailButton {
					&:focus {
					}
					.shareMailButtonIcon {
						color: $accentColor;
						&:hover {
						}
					}
				}
			}
		}
		.heroDevices {
			flex: 1 1 60%;
			.macbookPro {
				max-width: 80%;
			}
			.iPhone {
				top: -6rem;
				right: 3rem;
			}
		}
	}
}
/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) and (max-width: 1024px) {
	body {
		&:after {
			background-size: cover;
		}
	}
	.homeContent {
		.heroText {
			header {
				.title {
					.alphTitle {
					}
				}
			}
			.subtitle {
			}
			.description {
			}
			.leaveMailDescription {
			}
			.leaveMailGroup {
				.leaveMailInput {
					&:focus {
					}
				}
				.shareMailButton {
					&:focus {
					}
					.shareMailButtonIcon {
						color: $accentColor;
						&:hover {
						}
					}
				}
			}
		}
		.heroDevices {
			.macbookPro {
			}
			.iPhone {
				top: -3rem;
			}
		}
	}
}
/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
	body {
		overflow-y: hidden;
	}
	.homeContent {
		.heroText {
			header {
				.title {
					.alphTitle {
					}
				}
			}
			.subtitle {
			}
			.description {
			}
			.leaveMailDescription {
			}
			.leaveMailGroup {
				.leaveMailInput {
					&:focus {
					}
				}
				.shareMailButton {
					&:focus {
					}
					.shareMailButtonIcon {
						color: $accentColor;
						&:hover {
						}
					}
				}
			}
		}
		.heroDevices {
			.macbookPro {
			}
			.iPhone {
				top: -3rem;
			}
		}
	}
}
/* hi-res laptops and desktops */
@media (min-width: 1281px) {
	body {
		overflow-y: hidden;
	}
	.homeContent {
		.heroText {
			header {
				.title {
					.alphTitle {
					}
				}
			}
			.title {
				color: #9f9ea9;
			}
			.subtitle {
				color: #9f9ea9;
			}
			.description {
				color: #9f9ea9;
			}
			.leaveMailDescription {
				color: #9f9ea9;
			}
			.leaveMailGroup {
				.leaveMailInput {
					&:focus {
					}
				}
				.shareMailButton {
					&:focus {
					}
					.shareMailButtonIcon {
						color: $accentColor;
						&:hover {
						}
					}
				}
			}
		}
		.heroDevices {
			.macbookPro {
			}
			.iPhone {
			}
		}
	}
	.singlePage {
		/* Hero Text */
		.heroText {
			.subtitle,
			.description {
				background: rgba(0, 0, 0, 0.4);
				color: #dddddd;
			}
		}
		/* Single Go Back */
		.singlePageGoBack {
			margin-top: calc(0.0625rem * 50);
			padding-bottom: calc(0.0625rem * 10);
			.singlePageGoBackSpan {
				:hover {
					padding-bottom: calc(0.0625rem * 2);
					border-bottom: calc(0.0625rem * 1) dotted $accentColor;
				}
				.singlePageGoBackLink {
					color: $accentColor;
				}
			}
		}
	}
}
